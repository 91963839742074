import React, { Component } from "react";
import redlogo from "./../../logos/logo-red.png";
import { Link } from "react-router-dom";

function Header() {
  return (
    <nav class="navbar navbar-expand-lg fixed-top">
      <div class="container-fluid">
        <Link class="navbar-brand ms-3 fw-bold" to="#">
          <img src={redlogo} className="me-2" alt="" srcset="" height="40" />{" "}
          Cyphinix
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div
        class="collapse navbar-collapse ms-auto me-4"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item ms-3">
            <Link class="nav-link text-black" aria-current="page" to="/">
              Home
            </Link>
          </li>
          <li class="nav-item ms-3">
            <Link class="nav-link text-black" to="#">
              Blog
            </Link>
          </li>
          <li class="nav-item ms-3">
            <Link class="nav-link text-black" to="#">
              Pricing
            </Link>
          </li>
          <li class="nav-item ms-3 dropdown">
            <Link
              class="nav-link text-black dropdown-toggle"
              to="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Services
            </Link>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <Link className="dropdown-item" to="/service1">
                  Network Security Assessment
                </Link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <Link className="dropdown-item" to="/service2">
                  Application Security Assessment
                </Link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <Link className="dropdown-item" to="/service3">
                  Iot Security Assessment
                </Link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <Link className="dropdown-item" to="/service4">
                  Adversary Simulation
                </Link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <Link className="dropdown-item" to="/service5">
                  Blue Teaming Solutions
                </Link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <Link className="dropdown-item" to="/service6">
                  Security Training & Awareness
                </Link>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <Link className="dropdown-item" to="/service7">
                  Others
                </Link>
              </li>
            </ul>
          </li>
          <li class="nav-item ms-3">
            <Link class="nav-link text-black" to="#">
              About
            </Link>
          </li>
          <li class="nav-item ms-3">
            <Link class="nav-link text-black" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
